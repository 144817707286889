import React from "react";
import Layout from "../../components/Layout";
import data from "../../../content/resources/sector_salud.json";
import {Link} from "gatsby-plugin-modal-routing";

export default function bibliotecasDigitales() {
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div
                        className="services_banner"
                        style={{backgroundImage: `url(../../images/sector_salud/1.jpg)`}}
                    >
                        <h1>Bibliotecas Digitales</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Descripción</h1>
                        <p className="services_section_1__paragraph">
                            Soluciones integrales para docencia, investigación y práctica profesional. Incluye
                            publicaciones editadas por sellos de prestigio internacional. Plataformas integrales que
                            incluyen diversidad de contenidos con amplia solvencia técnica y científica.
                        </p>
                    </div>
                </div>
            </div>
        </Layout>
    )
}